import { useEffect, useState } from "react";
import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import * as estimateActions from "../../../../../redux/Estimation/estimationActions";
import Layout from "./Layout";
import { FIXED, TO_CAPITALIZED_WORDS } from "../../../../../components/config/constant";

const CampaignCalculations = ({ location, setLocation }: any) => {
  const [strategy, setStrategy] = useState("");
  const [strategyResult, setStrategyResult] = useState<any>({});

  let dispatch: any = useDispatch();

  const { id } = useParams();

  const { getCalculations, estimationForEdit } = useSelector(
    (state: any) => ({
      getCalculations: state?.estimator?.calculations,
      estimationForEdit: state?.estimator?.estimationForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(estimateActions?.campaignCalculation(id));
      dispatch(estimateActions?.getEstimations(id));
      setLocation(window.location.pathname);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (strategy) {
      setStrategyResult({ ...getCalculations[strategy] });
    }

    // eslint-disable-next-line
  }, [strategy]);

  useEffect(() => {
    if (strategyResult) {
      // eslint-disable-next-line
      let array = Object?.keys(strategyResult);
      // eslint-disable-next-line
      Object?.keys(strategyResult).map((item) => {
        // eslint-disable-next-line
        Object.keys(strategyResult[item]).map((subITem) => {});
      });
    }
    // eslint-disable-next-line
  }, [strategyResult]);

  return (
    <Layout>
      <div className="calculations">
        <div className="heading d-flex">
          <Link to="/dashboard">
            <div className="arrowBtn ">
              <MdOutlineArrowBackIosNew />
            </div>
          </Link>
          <h4 className="mt-2">Campaign Details</h4>
        </div>

        <div className="content mx-4">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4">
              <div className="strategy">
                <p className="mb-0 mt-5">Campaign type</p>
                <Select
                  options={
                    getCalculations
                      ? Object?.keys(getCalculations).map((item) => {
                          return { value: item, label: item };
                        })
                      : []
                  }
                  onChange={(data: any) => {
                    setStrategy(data.value);
                  }}
                />
              </div>
            </div>
          </div>
          {strategyResult &&
            Object?.keys(strategyResult).map((item: any, key: any) => {
              return (
                <div className="" key={key}>
                  <div className="mt-5">
                    <h4 className="mb-4">{TO_CAPITALIZED_WORDS(item)}</h4>
                    <div className="card ">
                      <div className="card-body">
                        <div className="heading">
                          <table className="table">
                            <thead className="mb-3">
                              <tr>
                                <th scope="col">Campaigns</th>
                                <th scope="col">Budget</th>
                                <th scope="col">CPC</th>
                                <th scope="col">Clicks</th>
                                <th scope="col">Impression</th>
                                <th scope="col">CTR</th>
                                {estimationForEdit?.campaignData?.objective !== "Reach" ? (
                                  <>
                                    {" "}
                                    <th scope="col">Conversions</th>
                                  </>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>

                            {Object?.keys(strategyResult[item]).map((subItem) => {
                              return (
                                <>
                                  <tbody>
                                    <th className="table-body" scope="row">
                                      {TO_CAPITALIZED_WORDS(subItem)}
                                    </th>
                                    <td>
                                      {FIXED(
                                        subItem !== "total"
                                          ? strategyResult[item][subItem].budget
                                          : strategyResult[item][subItem].TotalBudget
                                      )}
                                    </td>
                                    <td>
                                      {FIXED(
                                        subItem !== "total"
                                          ? strategyResult[item][subItem].cpc
                                          : strategyResult[item][subItem].totalCpc
                                      )}
                                    </td>
                                    <td>
                                      {FIXED(
                                        subItem !== "total"
                                          ? strategyResult[item][subItem].NoOfClicks
                                          : strategyResult[item][subItem].totalNoOfClicks
                                      )}
                                    </td>
                                    <td>
                                      {FIXED(
                                        subItem !== "total"
                                          ? strategyResult[item][subItem].impression
                                          : strategyResult[item][subItem].totalImpression
                                      )}
                                    </td>
                                    <td>
                                      {FIXED(
                                        subItem !== "total"
                                          ? strategyResult[item][subItem].ctr
                                          : strategyResult[item][subItem].totalctr
                                      )}
                                    </td>
                                    {estimationForEdit?.campaignData?.objective !== "Reach" ? (
                                      <>
                                        <td>
                                          {FIXED(
                                            subItem !== "total"
                                              ? strategyResult[item][subItem].conversion
                                              : strategyResult[item][subItem].totalConversions
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </tbody>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default CampaignCalculations;
