import React, { Fragment } from "react";
import LeadsImg from "../../../assets/images/leadesImg.svg";

const EstimatorCard = () => {
  return (
    <Fragment>
      <div className="estimator-card">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <img
                  src={LeadsImg}
                  alt=""
                  className="d-none d-lg-block leadsImg"
                />

                <div>
                  <p className="">These estimates are based on best industry</p>
                  <p className="">practices and other factors</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EstimatorCard;
