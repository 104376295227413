import React from "react";
import "rc-slider/assets/index.css";
import "../../../assets/scss/budgetText.scss";
import { TextField } from "@mui/material";

const MainSlider = ({ firstPageData, setFirstPageData }: any) => {
  const handleChange = (val: any) => {
    setFirstPageData({
      ...firstPageData,
      totalBudget: val.target.value,
    });
  };
  return (
    <>
      <label className="form-label">Budget</label>
      {/* <div className="alert mb-0 mt-0" style={{ display: `${firstPageData?.totalBudget > 999999 ? "block" : "none"}` }}>
        <div style={{ color: "red" }}>
          {" "}
          <b>You can not enter amount more than 6 digit</b>{" "}
        </div>
      </div> */}
      {/* <div className="  d-flex">
        <div className="sm-12 mt-2">
          <div>
            <input className="field" type="text" value={firstPageData?.totalBudget} onChange={handleChange} />
          </div>
        </div>
      </div>*/}
      <TextField
        fullWidth
        className="field"
        size="small"
        type="text"
        value={firstPageData?.totalBudget}
        onChange={handleChange}
      />
    </>

    // <div className="d-flex">
    //   <h4 className="mt-3">Total Budget (0 - 200k)$</h4>
    //   <div className="industryAges ml-4">
    //     <input type={"text"} onChange={handleChange} />
    //   </div>

    //   {/* <Slider min={0} max={200000} step={100} value={firstPageData.totalBudget} onChange={handleChange} /> */}
    // </div>
  );
};

export default MainSlider;
