import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as authAction from "../../redux/auth/authActions";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export function PasswordChangeModal({ show, onHide }: any) {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(authAction.logout());
    navigate("/Login");
  };
  return (
    <>
      <Modal show={show} onHide={handleClick}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <FaCheckCircle className="success-icon" />
            <h5>Your Password Has been changed Successfully</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
