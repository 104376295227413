import React from "react";

export const Objectives = ({ img, heading, about }: any) => {
  return (
    <div className="objective-card">
      <div className="card-body">
        <img className="icon" src={img} alt="..." />

        <h3 className="card-title mt-3">{heading}</h3>
        <p className="mt-4">{about}</p>
      </div>
    </div>
  );
};
