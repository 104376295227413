import React from "react";

export const MoreArticleItem = ({ img, name, about, heading, link }: any) => {
  return (
    <div className="moreArticle">
      <div className="border-0 card">
        <a href={link} target="_blank" rel="noreferrer">
          <div className="card-body">
            <h3 className="card-text text-dark">{about}</h3>
            {/* <p className="fs-2">{}</p> */}
            <div className="mt-4 mb-2 fs-4 d-flex">
              By <div className="ml-3  bloggerName">{name}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
