import * as requestFromServer from "./authCrud";
import * as estimationRequestFromServer from "../Estimation/estimationCrud";

import { authSlice, callTypes } from "./authSlice";
import toast from "react-hot-toast";

const { actions } = authSlice;

export const logout = () => (dispatch: any) => {
  dispatch(actions.logoutState());
};

export const login =
  (data: any, navigate: any, estimationData: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .login(data)
      .then((response) => {
        if (estimationData !== null) {
          estimationRequestFromServer
            .userEstimation(estimationData)
            .then((response) => {
              navigate(`/calculator/${response.data.id}`);
            })
            .catch((error) => {
              error.clientMessage = error.message;
              let errorData = error?.response?.data?.message;
              navigate("/");
              alert(errorData);
              dispatch(
                actions.catchError({
                  error,
                  callType: callTypes.action,
                  errorType: "createError",
                  errorData,
                })
              );
            });
        } else {
          navigate("/");
        }
        dispatch(
          actions.loginState({ token: response?.data?.tokens?.access?.token }),
          whoAmI()
        );
      })

      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;

        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "loginError",
            errorData,
          })
        );
      });
  };

export const signIn = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .sign(data)
    .then((response) => {
      dispatch(whoAmI());
      dispatch(
        actions.loginState({ token: response?.data?.tokens?.access?.token })
      );
    })
    .catch((err) => toast.error(err?.response?.data?.message));
};

export const register =
  (data: any, navigate: any, estimationData: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .register(data)
      .then((response) => {
        if (estimationData !== null) {
          estimationRequestFromServer
            .userEstimation(estimationData)
            .then((response) => {
              navigate(`/calculator/${response.data.id}`);
            })
            .catch((error) => {
              error.clientMessage = error.message;
              let errorData = error?.response?.data?.message;
              navigate("/");
              alert(errorData);
              dispatch(
                actions.catchError({
                  error,
                  callType: callTypes.action,
                  errorType: "createError",
                  errorData,
                })
              );
            });
        } else {
          navigate("/logIn");
        }
        dispatch(
          actions.signupState({ token: response?.data?.tokens?.access?.token }),
          whoAmI()
        );
      })
      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "signupError",
            errorData,
          })
        );
      });
  };

export const whoAmI = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .whoAmI()
    .then((response) => {
      dispatch(actions.whoAmIState({ entities: response?.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      // toast.error(error.response.data.message);
      dispatch(
        actions.catchError({ error: "error", callType: callTypes.list })
      );
    });
};

export const passwordForgot =
  (data: any, setModalShow: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .forgotPassword(data)
      .then((response) => {
        setModalShow(true);
        dispatch(
          actions.passwordForgot({
            token: response?.data?.tokens?.access?.token,
          })
        );
      })
      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "emailError",
            errorData,
          })
        );
      });
  };

export const resetPassword =
  (data: any, token: any, setModalShow: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .resetPassword(data, token)
      .then((response) => {
        setModalShow(true);
        dispatch(actions.resetPassword({}));
      })
      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "passwordError",
            errorData,
          })
        );
      });
  };

export const changePassword =
  (data: any, setModalShow: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .changePassword(data)
      .then((response) => {
        setModalShow(true);
        dispatch(actions.changePassword({}));
      })
      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "changePasswordError",
            errorData,
          })
        );
      });
  };
