import React from "react";
import Layout from "../../../components/Layout/header";
import ContactCard from "../../public/contact/contactCard";

const ContactUsPage = () => {
  return (
    <Layout>
      <ContactCard />
    </Layout>
  );
};
export default ContactUsPage;
