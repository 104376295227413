import React from "react";
import Layout from "../../../components/Layout/header";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="about" style={{color: "#000"}}>
        <h1 style={{color:"blue"}}>Campaign Estimator Corporation Terms and Conditions</h1>
        <p>
          <strong>Last Updated:</strong> 8 September 2023
        </p>

        <p>
          Campaign Estimator Corporation ("we," "our," or "us") welcomes you to
          our website located at [Insert Website URL] (the "Website"). By
          accessing or using our Website, you agree to comply with and be bound
          by the following terms and conditions ("Terms"). If you do not agree
          with these Terms, please do not use the Website.
        </p>

        <h2>1. Use of the Website</h2>
        <h3>1.1. Eligibility</h3>
        <p>
          You must be at least 18 years old to use our Website. By using the
          Website, you represent and warrant that you are at least 18 years old
          and capable of entering into a legally binding agreement.
        </p>

        <h3>1.2. User Account</h3>
        <p>
          Some areas of the Website may require you to create a user account.
          You are responsible for maintaining the confidentiality of your
          account information and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account.
        </p>

        <h3>1.3. Prohibited Activities</h3>
        <p>You agree not to:</p>
        <ul>
          <li>Use the Website for any unlawful or unauthorized purpose.</li>
          <li>Interfere with or disrupt the Website or servers.</li>
          <li>
            Attempt to access any portion of the Website that you are not
            authorized to access.
          </li>
          <li>
            Engage in any form of harassment, hate speech, or harmful conduct on
            the Website.
          </li>
        </ul>

        <h2>2. Content</h2>
        <h3>2.1. User-Generated Content</h3>
        <p>
          You may submit user-generated content to the Website, including
          comments, reviews, and feedback. By submitting content, you grant us a
          non-exclusive, worldwide, royalty-free license to use, reproduce,
          modify, and distribute your content for any purpose.
        </p>

        <h3>2.2. Content Removal</h3>
        <p>
          We reserve the right to remove, edit, or modify user-generated content
          that violates these Terms or is otherwise objectionable.
        </p>

        <h3>2.3. Copyright</h3>
        <p>
          All content on the Website, including text, graphics, logos, images,
          and software, is the property of Campaign Estimator Corporation or its
          licensors and is protected by copyright laws.
        </p>

        <h2>3. Privacy</h2>
        <p>
          Your use of the Website is also governed by our Privacy Policy. By
          using the Website, you consent to the collection, use, and disclosure
          of your information as described in the Privacy Policy.
        </p>

        <h2>4. Disclaimer of Warranties</h2>
        <p>
          The Website is provided "as is" and "as available" without warranties
          of any kind, either express or implied. We make no warranties or
          representations regarding the accuracy, reliability, or availability
          of the Website.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Campaign Estimator Corporation
          shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from:
        </p>
        <ul>
          <li>Your use or inability to use the Website.</li>
          <li>
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein.
          </li>
          <li>
            Any interruptions, errors, bugs, viruses, or other harmful
            components.
          </li>
        </ul>

        <h2>6. Governing Law</h2>
        <p>
          These Terms are governed by the laws of the State of Delaware, United
          States, without regard to its conflict of law principles.
        </p>

        <h2>7. Changes to Terms and Website</h2>
        <p>
          We reserve the right to modify or update these Terms at any time.
          Changes will be effective immediately upon posting on the Website. We
          may also make changes to the Website's content, features, or
          functionality at any time without notice.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms or the
          Website, please contact us at:
        </p>
        <address>
          <p>Campaign Estimator Corporation</p>
          <p>8 The Green, Ste R</p>
          <p>Dover, DE 19901</p>
          <p>Email: campaignestimator@gmail.com</p>
        </address>
      </div>
    </Layout>
  );
};
export default TermsAndConditions;
