import React, { Fragment, useEffect, useState } from "react";
import { InputText } from "../../../components/forms/inputText";
import No1 from "../../../assets/images/SrNo.svg";
import No2 from "../../../assets/images/SrNo2.svg";
import No3 from "../../../assets/images/SrNo3.svg";
import No4 from "../../../assets/images/No4.svg";
import CalculatorResult from "./calculator-result-card";
import EstimatorCard from "./estimator-card";
import BudgetSlider from "../../../components/forms/formComponents/slider";
import * as estimateActions from "../../../redux/Estimation/estimationActions";
import LockIcon from "../../../assets/images/Ellipse 8.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as authActions from "../../../redux/auth/authActions";
import Layout from "../../../components/Layout/header";
let calculatorInitState: {
  Sem: 0;
  socialMedia: 0;
  display: 0;
  affiliates: 0;
};

const Calculator = ({ setLocation }: any) => {
  let dispatch: any = useDispatch();

  const { id } = useParams();

  const [initCalculatorState, setInitCalculatorState] =
    useState(calculatorInitState);

  const [calculationsData, setCalculationsData] = useState<any>({
    sem: 0,
    socialMedia: 0,
    display: 0,
    affiliates: 0,
  });

  const { estimation, user } = useSelector(
    (state: any) => ({
      estimation: state?.estimator?.estimationForEdit,
      user: state?.authentication?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(authActions.whoAmI());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(estimateActions.getEstimations(id));
      setLocation(window.location.pathname);
    }
    // eslint-disable-next-line
  }, [id]);

  const totalSem = calculationsData?.sem;
  const totalSocialMedia = calculationsData?.socialMedia;
  const totalDisplay = calculationsData?.display;
  const totalAffiliates = calculationsData?.affiliates;

  useEffect(() => {
    if (estimation) {
      setCalculationsData({
        sem: estimation?.percentageBudget?.semPercent,
        socialMedia: estimation?.percentageBudget?.socialMediaPercent,
        display: estimation?.percentageBudget?.displayPercent,
        affiliates: estimation?.percentageBudget?.affiliatesPercent,
      });
    }
  }, [estimation]);
  return (
    <Layout>
      <Fragment>
        <div className="calculator">
          <div className="container">
            <div className="text-center ">
              <div className="main-text">Campaign Estimator</div>
              <p className="mt-3">Here you estimate your Results.</p>
              <div className="fs-3 fw-bold mt-4 ">
                Campaign Budget Calculator
              </div>
            </div>
            <div
              className="alert"
              role={"alert"}
              style={{
                display: `${
                  totalSem + totalSocialMedia + totalDisplay + totalAffiliates >
                  101
                    ? "block"
                    : "none"
                }`,
              }}
            >
              Your Budget Split in fields are more than your Total Budget
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <div className="disable-icon">
                  {user && user?.paidUser === true ? null : (
                    <img className="icon" src={LockIcon} alt="" />
                  )}
                  <div
                    className={` ${
                      user && user?.paidUser === true ? "" : "disable"
                    }`}
                  >
                    <div className="bdgt-selector">
                      <div className="d-block d-md-flex">
                        <div className="SrImg">
                          <img src={No1} alt="" />
                        </div>
                        <div className="w-100">
                          <h3 className="fw-bold">SEM (Search)</h3>
                          <p>
                            {" "}
                            SEM advertising platforms like Google Search, Bing
                            and Yandex.
                          </p>
                          <div className="bdgt-split text-end mb-3">
                            Budget Split {Number(calculationsData?.sem)} %
                          </div>

                          <div className="d-flex">
                            <InputText
                              type="number"
                              value={totalSem}
                              onChange={(e: number | any) => {
                                if (user && user.paidUser) {
                                  setInitCalculatorState({
                                    ...initCalculatorState,
                                    Sem: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>

                          <BudgetSlider
                            state={calculationsData?.sem}
                            onChange={(value: number) => {
                              if (user && user.paidUser) {
                                setCalculationsData({
                                  ...calculationsData,
                                  sem: value,
                                });
                              }
                            }}
                            min={0}
                            max={100}
                            step={1}
                            onFinalChange={(val: number) => {
                              if (user && user.paidUser) {
                                const data = {
                                  percentageBudget: {
                                    semPercent: val,
                                    socialMediaPercent:
                                      estimation?.percentageBudget
                                        ?.socialMediaPercent,
                                    displayPercent:
                                      estimation?.percentageBudget
                                        ?.displayPercent,
                                    affiliatesPercent:
                                      estimation?.percentageBudget
                                        ?.affiliatesPercent,
                                  },
                                };
                                dispatch(
                                  estimateActions.updateEstimation(data, id)
                                );
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-block d-md-flex">
                        <div className="SrImg">
                          <img src={No2} alt="" />
                        </div>

                        <div className="w-100">
                          <h3 className="fw-bold">Social Media</h3>
                          <p>
                            {" "}
                            Social Media advertising platforms like Facebook,
                            Instagram, Snapchat, Twitter and TikTok.
                          </p>
                          <div className="bdgt-split text-end mb-3">
                            Budget Split {calculationsData?.socialMedia} %
                          </div>

                          <div className="d-flex">
                            <InputText
                              type="number"
                              value={totalSocialMedia}
                              onChange={(e: number | any) => {
                                if (user && user.paidUser) {
                                  setInitCalculatorState({
                                    ...initCalculatorState,
                                    socialMedia: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>

                          <BudgetSlider
                            state={calculationsData?.socialMedia}
                            onChange={(value: number) => {
                              if (user && user.paidUser) {
                                setCalculationsData({
                                  ...calculationsData,
                                  socialMedia: value,
                                });
                              }
                            }}
                            min={0}
                            max={100}
                            step={1}
                            onFinalChange={(val: number) => {
                              if (user && user.paidUser) {
                                const data = {
                                  percentageBudget: {
                                    semPercent:
                                      estimation?.percentageBudget?.semPercent,
                                    socialMediaPercent: val,
                                    displayPercent:
                                      estimation?.percentageBudget
                                        ?.displayPercent,
                                    affiliatesPercent:
                                      estimation?.percentageBudget
                                        ?.affiliatesPercent,
                                  },
                                };
                                dispatch(
                                  estimateActions.updateEstimation(data, id)
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-block d-md-flex">
                        <div className="SrImg">
                          <img src={No3} alt="" />
                        </div>

                        <div className="w-100">
                          <h3 className="fw-bold">Display</h3>
                          <p>
                            Display advertising platforms like DV360, Google
                            Display, Third party display platform.
                          </p>

                          <div className="bdgt-split text-end mb-3">
                            Budget Split {calculationsData?.display} %
                          </div>

                          <div className="d-flex">
                            <InputText
                              placeholder={"5,000"}
                              type="number"
                              value={totalDisplay}
                              onChange={(e: number | any) => {
                                if (user && user.paidUser) {
                                  setInitCalculatorState({
                                    ...initCalculatorState,
                                    display: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>

                          <BudgetSlider
                            state={calculationsData?.display}
                            onChange={(value: number) => {
                              if (user && user.paidUser) {
                                setCalculationsData({
                                  ...calculationsData,
                                  display: value,
                                });
                              }
                            }}
                            min={0}
                            max={100}
                            step={1}
                            onFinalChange={(val: number) => {
                              if (user && user.paidUser) {
                                const data = {
                                  percentageBudget: {
                                    semPercent:
                                      estimation?.percentageBudget?.semPercent,
                                    socialMediaPercent:
                                      estimation?.percentageBudget
                                        ?.socialMediaPercent,
                                    displayPercent: val,
                                    affiliatesPercent:
                                      estimation?.percentageBudget
                                        ?.affiliatesPercent,
                                  },
                                };
                                dispatch(
                                  estimateActions.updateEstimation(data, id)
                                );
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-block d-md-flex">
                        <div className="SrImg">
                          <img src={No4} alt="" />
                        </div>

                        <div className="w-100">
                          <h3 className="fw-bold">Affiliates</h3>
                          <p>
                            Affiliates network for your traffic, lead generation
                            or sales.
                          </p>

                          <div className="bdgt-split text-end mb-3">
                            Budget Split {calculationsData?.affiliates} %
                          </div>

                          <div className="d-flex">
                            <InputText
                              placeholder={"5,000"}
                              type="number"
                              value={totalAffiliates}
                              onChange={(e: number | any) => {
                                if (user && user.paidUser) {
                                  setInitCalculatorState({
                                    ...initCalculatorState,
                                    affiliates: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>

                          <BudgetSlider
                            state={calculationsData?.affiliates}
                            onChange={(value: number) => {
                              if (user && user.paidUser) {
                                setCalculationsData({
                                  ...calculationsData,
                                  affiliates: value,
                                });
                              }
                              
                            }}
                            min={0}
                            max={100}
                            step={1}
                            onFinalChange={(val: number) => {
                              if (user && user.paidUser) {
                                const data = {
                                  percentageBudget: {
                                    semPercent:
                                      estimation?.percentageBudget?.semPercent,
                                    socialMediaPercent:
                                      estimation?.percentageBudget
                                        ?.socialMediaPercent,
                                    displayPercent:
                                      estimation?.percentageBudget
                                        ?.displayPercent,
                                    affiliatesPercent: val,
                                  },
                                };
                                dispatch(
                                  estimateActions.updateEstimation(data, id)
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2"></div>
              <div className="col-lg-3">
                <CalculatorResult
                  user={user}
                  estimation={estimation?.estimationResults}
                  totalSem={totalSem}
                  totalSocialMedia={totalSocialMedia}
                  totalDisplay={totalDisplay}
                  totalAffiliates={totalAffiliates}
                />
              </div>
            </div>
          </div>
        </div>
        <EstimatorCard />
      </Fragment>
    </Layout>
  );
};
export default Calculator;
