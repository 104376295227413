import React from "react";

import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";

const Footer = ({ location, setLocation }: any) => {
  const navigate = useNavigate();
  return (
    <footer>
      <section>
        <div className="container">
          <hr />
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 col-sm-12">
              <h5 className="d-flex justify-content-center justify-content-md-start">Copyright © 2022 Campaign Estimator.</h5>
              <div className="d-flex justify-content-center justify-content-md-start">
                <p
                  className="privacy-btn"
                  onClick={() => navigate("/terms-conditions")}
                >
                  Terms and Condition{" "}
                </p>
                <div className="divider"></div>
                <p className="privacy-btn" onClick={() => navigate("/privacy")}>
                  Privacy Policy{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-5 brand-icons fs-4 d-flex justify-content-center justify-content-md-end">
              <FaFacebookF className="brandNestIcons" />
              <FaTwitter className="brandNestIcons" />
              <ImLinkedin2 />
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
