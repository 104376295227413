import * as requestFromServer from "./contactCrud";
import { connectSlice, callTypes } from "./contactSlice";

const { actions } = connectSlice;

export const postMessage = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .contactUs(data)
    .then((response: any) => {
      dispatch(actions.messagePost({ entities: response.data }));
    })
    .catch((error: any) => {
      error.clientMessage = error.message;
      let errorData = error?.response?.data?.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "createError",
          errorData,
        })
      );
    });
};
export const getMediumBlogs = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMediumBlogs()
    .then((response) => {
      dispatch(
        actions.getMediumBlogs({
          entitiy: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
