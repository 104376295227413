import React from "react";
import { MoreArticleItem } from "./articleList/moreArticleItem";

import { useSelector, shallowEqual } from "react-redux";

const MoreArticle = ({ location, setLocation }: any) => {
  const { mediumData } = useSelector(
    (state: any) => ({
      mediumData: state?.connect?.getMediumBlogs,
    }),
    shallowEqual
  );

  return (
    <React.Fragment>
      <article>
        <>
          <div className="container mt-4">
            <div className="row">
              {mediumData?.length
                ? mediumData.map((item: any, index: any) => {
                    return (
                      <div key={index} className="col-lg-6">
                        <MoreArticleItem name={item.author} about={item.title} link={item.link} />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </>
      </article>
    </React.Fragment>
  );
};

export default MoreArticle;
