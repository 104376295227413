import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CenteredContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f5f5f5",
});

const FancyCard = styled(Card)(({ theme }) => ({
  width: 600,
  backgroundColor: "#fff", // Card background color
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  padding: theme.spacing(2),
}));

const FancyHeader = styled(Typography)(({ theme }) => ({
  fontSize: 28,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const FancyMessage = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  textAlign: "center",
  marginBottom: theme.spacing(2),
  color: "#555",
}));

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "16px",
});

const BackButton = styled(Button)(() => ({
  textDecoration: "none",
}));

function CongratulationsCard() {
  const handleClick = () => {
    window.location.href = "/dashboard";
  };

  return (
    <CenteredContainer>
      <FancyCard>
        <CardContent>
          <FancyHeader>Congratulations!</FancyHeader>
          <FancyMessage>
            Your payment was successful. Thank you for your business! We
            appreciate your trust and look forward to serving you again.
          </FancyMessage>
        </CardContent>
      </FancyCard>
      <ButtonContainer>
        <BackButton onClick={handleClick} variant="outlined" color="primary">
          Proceed to Dashboard
        </BackButton>
      </ButtonContainer>
    </CenteredContainer>
  );
}

export default CongratulationsCard;
