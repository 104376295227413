import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  REQUIRED_FIELD,
  validationSchemaSignIn,
} from "../../../components/config/validation";
import { InputText } from "../../../components/forms/inputText";
import MainLogo from "../../../assets/images/MainLogo1.svg";
import * as authActions from ".././../../redux/auth/authActions";

let initValue = {
  name: "",
  email: "",
  password: "",
};

const SignupForm = ({ location, setLocation }: any) => {
  let navigate = useNavigate();
  const dispatch: any = useDispatch();
  const {
    // watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initValue,
    resolver: yupResolver(validationSchemaSignIn),
  });

  const { signupError, estimationData} = useSelector(
    (state: any) => ({
      signupError: state?.authentication?.signupError,
      estimationData: state?.estimator?.initDataStore,
      
    }),
    shallowEqual
  );

  const onSubmit = async (data: any) => {
    dispatch(authActions.register(data,navigate, estimationData));
  };
  useEffect(() => {
    setLocation(window.location.pathname)
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container ">
          <div className="row">
            <div>
              <div className="text-center">
                <Link to={"/"}>
                  <img src={MainLogo} alt="" className="logoImg" />
                </Link>
              </div>
              <div className="col-lg-12 main-card ">
                <div className="text-center mb-3"></div>
                <div className="signUp-form ">
                  <div className="card border-0">
                    <div className="card-body">
                      <h3>Let’s partner up</h3>
                      <div className="error" >{signupError}</div>
                      <div className="name">
                        <p>Name</p>
                        <InputText
                          className={"w-100"}
                          type="text"
                          name="name"
                          register={register}
                          validations={{
                            ...{
                              required: {
                                value: true,
                                message: `${REQUIRED_FIELD}`,
                              },
                              pattern: {
                                value: EMAIL_REGEX,
                                message: `${REQUIRED_FIELD}`,
                              },
                            },
                          }}
                          error={errors["name"]}
                          autoFocus
                        />
                      </div>

                      <div className="mt-4">
                        <p>Email</p>
                        <InputText
                          className={"w-100"}
                          type="text"
                          name="email"
                          register={register}
                          validations={{
                            ...{
                              required: {
                                value: true,
                                message: `${REQUIRED_FIELD}`,
                              },
                              pattern: {
                                value: EMAIL_REGEX,
                                message: `${REQUIRED_FIELD}`,
                              },
                            },
                          }}
                          error={errors["email"]}
                          autoFocus
                        />
                      </div>

                      <div className="mt-4 mb-4">
                        <p>Password</p>
                        <InputText
                          className={"w-100"}
                          type="password"
                          name="password"
                          register={register}
                          validations={{
                            ...{
                              required: {
                                value: true,
                                message: `${REQUIRED_FIELD}`,
                              },
                              pattern: {
                                value: PASSWORD_REGEX,
                                message: `${REQUIRED_FIELD}`,
                              },
                            },
                          }}
                          error={errors["password"]}
                          autoFocus
                        />
                      </div>

                      <div className="text-end  ">
                        <Link to={"/verification"} className="textforget">
                        Forgot your password?
                        </Link>
                      </div>

                      <div className="text-center mt-4 ">
                        <button
                          type="submit"
                          className="loginBtn border-0 mb-2"
                        >
                          Sign Up
                        </button>
                      </div>
                      <div className="text-center mt-4">
                      <div className="textforget mb-3">
                        Already Have Account?
                        <Link className="signUp" to={"/LogIn"}>
                          LogIn
                        </Link>
                      </div>
                    </div>
                      <div className="text-center mt-4">
                      <div className="textforget mb-3">
                      I agree to
                        <Link className="signUp" to={"/termConditions"}>
                        Terms & Conditions
                        </Link>
                      </div>
                    </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default SignupForm;
