import React from "react";

const PaymentCardInfo = () => {
  return (
    <div>
      <div className="card border-0">
        <div className="header">
          <div className="header-text">
            <div className="d-flex">
              <div>Order Summary</div>
              <p className="text-end">Price in USD</p>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="body-text">
            <div className="d-flex">
              <div>keyword Tool Pro Plus (12-months subscription)</div>
              <p>$79*12 months = $948</p>
            </div>
          </div>
          <hr />
          <div className=" mt-4 mb-4 d-flex justify-content-end">
            <h4 className="fw-bold">Payment Due</h4>
            <h5 className="fw-bold">$948</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCardInfo;
