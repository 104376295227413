import React from "react";
import { TextField } from "@mui/material";
interface InputTextProps {
  label?: String;
  inputInfo?: String;
  display?: String;
  placeholder?: String;
  className?: String;
  type?: String;
  inputSize?: String;
  id?: String;
  name?: String;
  value?: any;
  accept?: any;
  min?: String;
  max?: String;
  onChange?: any;
  onBlur?: any;
  prefix?: any;
  prefixAction?: any;
  postfix?: any;
  postfixAction?: any;
  validations?: any;
  register?: any;
  required?: any;
  error?: any;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  textarea?: boolean;
}

export const InputText = ({
  label,
  inputInfo,
  placeholder = "",
  type = "text",
  display = "block",
  className = "",
  inputSize = "",
  name = "",
  id = "",
  value = "",
  accept = "",
  min = "0",
  max,
  prefix,
  prefixAction = () => {},
  postfix,
  postfixAction = () => {},
  onChange = () => {},
  onBlur = () => {},
  validations,
  register,
  required,
  error,
  disabled = false,
  readonly = false,
  autoFocus = true,
  textarea = false,
  ...otherProps
}: InputTextProps) => {
  return (
    <React.Fragment>
      <div
        className={`input-group ${
          display ? `d-${display}` : ""
        } ${className} ${inputSize} `}
      >
        {label && (
          <label className="input-label">
            {label} {validations?.required && <sup className="required">*</sup>}
          </label>
        )}
        {inputInfo && <p className="input-info">{inputInfo}</p>}
        <div className="input-wrap">
          {prefix && (
            <div
              className={`input-group-prepend ${
                prefixAction ? "has-action" : ""
              }`}
              onClick={() => prefixAction()}
              {...otherProps}
            >
              {prefix}{" "}
            </div>
          )}
          {!textarea ? (
            <input
              className={`input-control`}
              style={{ padding: "15px" }}
              placeholder={`${placeholder}`}
              name={name}
              type={type}
              {...(register
                ? register(name, { ...validations })
                : { value: value, onChange: onChange })}
              {...(register
                ? register(name, { ...validations })
                : { value: value, onBlur: onBlur })}
              accept={accept}
              disabled={disabled}
              readOnly={readonly}
              min={min}
              max={max}
              {
                // autoFocus
                ...otherProps
              }
            ></input>
          ) : (
            <TextField
              id="outlined-textarea"
              // label="Multiline Placeholder"
              className={`input-control`}
              placeholder={`${placeholder}`}
              name={name}
              type={type}
              maxRows={4}
              rows={3}
              fullWidth
              {...(register
                ? register(name, { ...validations })
                : { value: value, onChange: onChange })}
              {...(register
                ? register(name, { ...validations })
                : { value: value, onBlur: onBlur })}
              accept={accept}
              disabled={disabled}
              readOnly={readonly}
              min={min}
              max={max}
              {...otherProps}
              multiline
            />
          )}
          {postfix && (
            <div
              className={`input-group-append ${
                postfixAction ? "has-action" : ""
              }`}
              onClick={() => postfixAction()}
              {...otherProps}
            >
              {" "}
              {postfix}
            </div>
          )}
        </div>
        {error && (
          <span
            style={{ color: "red" }}
            className={`${error ? "input-validation-error" : ""}`}
          >
            {error ? error?.message : undefined}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};
