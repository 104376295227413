import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MainLogo from "../../../../assets/images/MainLogo1.svg";
import Layout from "../../../../components/Layout/header";
import {
  PASSWORD_REGEX,
  REQUIRED_FIELD,
  validationSchemaChangePassword,
} from "../../../../components/config/validation";
import * as authActions from ".././../../../redux/auth/authActions";
import { InputText } from "../../../../components/forms/inputText";
import { PasswordChangeModal } from "../../../../components/dialogs/PasswordChangeSucces";
let initValue = {
  oldPassword: "",
  newPassword: "",
};
const ChangePassword = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch: any = useDispatch();
  const {
    // watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initValue,
    resolver: yupResolver(validationSchemaChangePassword),
  });

  const { changepasswordError } = useSelector(
    (state: any) => ({
      changepasswordError: state?.authentication?.changepasswordError,
    }),
    shallowEqual
  );

  const onSubmit = async (data: any) => {
    dispatch(authActions.changePassword(data, setModalShow));
  };

  return (
    <Fragment>
      <Layout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mb-5 ">
            <div className="row">
              <div className="text-center">
                <Link to={"/"}>
                  <img src={MainLogo} alt="" />
                </Link>
              </div>
              <div className="col-lg-12 main-card   ">
                <div className="signUp-form ">
                  <div className="error text-center mb-3">{changepasswordError}</div>
                  <div className="card border-0">
                    <div className="card-body">
                      <h3>Change your password</h3>

                      <div className="mb-3 mt-4">
                        <p>Current Password</p>
                        <InputText
                          className={"w-100"}
                          type="password"
                          name="oldPassword"
                          register={register}
                          validations={{
                            ...{
                              required: {
                                value: true,
                                message: `${REQUIRED_FIELD}`,
                              },
                            },
                          }}
                          error={errors["oldPassword"]}
                        />
                      </div>

                      <div className="email mb-3">
                        <p>New Password</p>
                        <InputText
                          className={"w-100"}
                          type="password"
                          name="newPassword"
                          register={register}
                          validations={{
                            ...{
                              required: {
                                value: true,
                                message: `${REQUIRED_FIELD}`,
                              },
                              pattern: {
                                value: PASSWORD_REGEX,
                                message: `${REQUIRED_FIELD}`,
                              },
                            },
                          }}
                          error={errors["newPassword"]}
                          autoFocus
                        />
                      </div>

                      <div className="text-center ">
                        <button type="submit" className="loginBtn border-0 mb-2 mt-3 ">
                          Confirm
                        </button>

                        <Link to={"/"}>
                          <button className="returnBtn border-0 mt-4 mb-4">Back</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PasswordChangeModal show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </form>
      </Layout>
    </Fragment>
  );
};

export default ChangePassword;
