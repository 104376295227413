import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const Campaigns = ({ name, industry, id }: any) => {
  return (
    <div>
      <div className="card mt-5">
        <div className="card-body">
          <div className="head">
            <div className="text-end">
              <FaRegHeart className="icon" />
            </div>
          </div>

          <div className="body">
            <h5>{name}</h5>
            <p className="industry">{industry?.title}</p>
          </div>
        </div>

        <div className="footer ">
          <div className="mt-2 row">
            <div className="col-md-5 col-sm-12  text-start">
              {" "}
              <Link to={`/campaign-calculations/${id}`}>
                <div className="deafult">
                  <p>View-details</p>
                </div>
              </Link>
            </div>
            <div className="col-md-7 col-sm-12 text-end">
              <Link to={`/calculator/${id}`}>
                <div className="deafult ">
                  <p>Manage Estimations</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
