import React, { Fragment, useEffect } from "react";
import audienceImg from "../../../assets/images/audience1.svg";
import SrNo from "../../../assets/images/SrNo.svg";
import SrNo2 from "../../../assets/images/SrNo2.svg";
import SrNo3 from "../../../assets/images/SrNo3.svg";
import MobileScreen from "../../../assets/images/MobileScreen.svg";

import { Objectives } from "../objectives/objective-lists";
import { OBJECTIVES_VALUES } from "../../../components/config/constant";
import ContactCard from "../contact/contactCard";
import MoreArticle from "../article/moreArticle";
import ChatCard from "./contentCards/chatCard";
import BudgetCard from "./contentCards/budgetCard";
import * as authActions from "../../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import Layout from "../../../components/Layout/header";
import * as connectActions from "../../../redux/contact/contactActions";

const HomePage = ({ setLocation }: any) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(authActions.whoAmI());
    setLocation(window.location.pathname);
    dispatch(connectActions.getMediumBlogs());

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Fragment>
        <section>
          <div className="container">
            <div className="text-center mt-5">
              <h3 className="main-text">Get an estimation for your digital campaigns.</h3>
            </div>
            <BudgetCard />
            <div className="main-text text-center mb-3">What, Why and where. We help you with these 3 things with Campaign Estimator</div>

            <div className="card ">
              <div className="card-body mt-5 ">
                <div className="row">
                  <div className="col-md-4 ml-5  ">
                    <img src={SrNo} alt="" />
                    <h3 className="mt-4 ">Audience Reach</h3>
                    <p className="fs-5 mt-4">
                      Think of reach as the number of unique people who see your content. In a perfect world, every one
                      of your followers would see every piece of content you posted.
                    </p>
                  </div>
                  <div className="col-md-3 d-none d-xl-block"></div>
                  <div className="col-md-5 audienceImg d-none d-md-block ">
                    <img src={audienceImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 ">
                <div className="card">
                  <div className="card-body mt-5">
                    <img src={SrNo2} alt="" />
                    <h3 className="mt-3">Lead Generation</h3>
                    <p>
                      Stimulate and capture interest in a product or service for the purpose of developing sales
                      pipeline.
                    </p>
                    <div className="text-center d-none d-md-block">
                      <img src={MobileScreen} alt="MobileScreen" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body mt-5">
                    <img src={SrNo3} alt="" />
                    <h3 className="mt-3">Conversion Rate</h3>
                    <p>
                      Conversion marketing is marketing with the intention of increasing conversions--that is, site
                      visitors who are paying customers.
                    </p>
                    <div className="d-none d-md-block text-center ">
                      <img src={MobileScreen} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-text text-center mt-5 mb-3 fw-900">Powerful Algorithm Authentic Results</div>
            <div className="row">
              {OBJECTIVES_VALUES?.length
                ? OBJECTIVES_VALUES.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-4">
                        <Objectives img={item.img} heading={item.heading} about={item.about} />
                      </div>
                    );
                  })
                : null}
            </div>
            <div>
              <ContactCard />
            </div>
            <div>
              <div className="text-center mt-5">
                <h3 className="fs-1 fw-bolder">Latest From the Campaign Estimator Blog</h3>
              </div>
              <MoreArticle />
            </div>
            <div className="mb-5 mt-5">
              <ChatCard />
            </div>
          </div>
        </section>
      </Fragment>
    </Layout>
  );
};
export default HomePage;
