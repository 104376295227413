import React, { Fragment, useEffect } from "react";
import MainFooter from "../../../../components/Layout/footer/mainFooter";
import { InputText } from "../../../../components/forms/inputText";
import PaymentCardInfo from "./paymentCardInfo";

let paymentInitState = {
  fname: "",
  lname: "",
  email: "",
  number: 0,
};



const Payment = ({ location, setLocation }: any) => {

  
  useEffect(() => {
    setLocation(window.location.pathname)
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className="paymentCard">
        <div className="container">
          <div className="text-center">
            <h3 className="fs-2 fw-bold mb-5">Complete your Payment</h3>
          </div>
          <div className="container">
            <PaymentCardInfo />
            <div className="card mt-4">
              <div className="card-body">
                <h4>Contact Information</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="fname">First Name</label>
                    <div>
                      <InputText
                        type={"text"}
                        id={"fname"}
                        name="firstname"
                        value={paymentInitState.fname}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="lname">Last Name</label>
                    <div>
                      <InputText
                        type={"text"}
                        id={"lname"}
                        name="lastname"
                        value={paymentInitState.lname}
                      />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-6">
                    <label htmlFor="email">Email Address</label>
                    <div>
                      <InputText
                        type={"email"}
                        id={"email"}
                        value={paymentInitState.email}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 ">
                    <label htmlFor="lname">Phone Number</label>
                    <div>
                      <InputText
                        type={"number"}
                        id={"number"}
                        value={paymentInitState.number}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-5" />
                <h4>Enter your Payment Details</h4>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        id={"payment"}
                        value={"payment"}
                        name={"payment"}
                      />
                      <label htmlFor="payment">Visa / Master Card</label>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        id={"American payment"}
                        value={"American payment"}
                        name={"payment"}
                      />

                      <label htmlFor="American payment">American Express</label>
                    </div>
                  </div>
                </div>
              </form>

              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="fname">Name as per card</label>
                    <div>
                      <input type="text" id="name" name="firstname" />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="lname">Debit / Credit Card number*</label>
                    <div>
                      <InputText type={"number"} id={"card"} />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-6">
                    <label htmlFor="text">Expiration Date*</label>
                    <div>
                      <InputText type={"number"} id={"name"} />
                    </div>
                  </div>

                  <div className="col-lg-6 ">
                    <label htmlFor="CVV">CVV*</label>
                    <div>
                      <InputText type={"number"} id={"name"} />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="subscribe">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </Fragment>
  );
};

export default Payment;
