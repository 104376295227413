import React from "react";
import { useNavigate } from "react-router-dom";

const ChatCard = () => {
  const navigate = useNavigate();
  return (
    <div className="chatCard ">
      <div className="card">
        <div className="row">
          <div className="col-lg-12">
            <div className="card-body text-center">
              <h3 className="fs-2">
                Ready to start budget estimation for your business?
              </h3>
              <p className="mt-4">
                Contact us today or ping us in the chat box for a faster reply
              </p>
              <button className="mt-3 chatBtn fs-6 fw-bold" onClick={() => navigate('/contact')}>Let's Chat!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
