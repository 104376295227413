import { createSlice } from "@reduxjs/toolkit";

const initialEstimationState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  entities: null,
  estimations: null,
  estimationForEdit: null,
  industries: null,
  objectives: null,
  estimationError: undefined,
  payment: undefined,
  campaigns: undefined,
  calculations: null,
  updateEstimations: null,
  initDataStore: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const estimationSlice = createSlice({
  name: "estimatations",
  initialState: initialEstimationState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }

      if (action.payload.errorType === "createError") {
        state.estimationError = action.payload?.errorData;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.estimationError = undefined;
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    industriesFetched: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.industries = entities;
    },
    estimationsPost: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.estimations = entities;
    },
    initDataStore: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.initDataStore = entities;
    },
    estimationUpdate: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.updateEstimations = entities;
    },
    getEstimation: (state, action) => {
      const { entitiy } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.estimationForEdit = entitiy;
    },
    postPayment: (state, action) => {
      const { entitiy } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.payment = entitiy;
    },

    getCampaignEstimation: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.campaigns = entities;
    },

    getEstimationById: (state, action) => {
      const { entity } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.calculations = entity;
    },
    getVerifyPayment: (state, action) => {
      const { entity } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.payment = entity;
    },
    resetEstimationState: (state) => {
      state.loading = false;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.estimations = null;
      state.estimationForEdit = null;
      state.estimationError = undefined;
      state.calculations = null;
      state.updateEstimations = null;
      state.initDataStore = null;
    },
  },
});
