import personScreen from "../../../src/assets/images/screenPerson.png";
import devices from "../../../src/assets/images/devicesimg.png";
import CountImg from "../../../src/assets/images/CountImg.png";
import leadership1 from "../../../src/assets/images/leadership1.svg";
import leadership2 from "../../../src/assets/images/leadership2.svg";
import leadership3 from "../../../src/assets/images/leadership3.svg";
import leadership4 from "../../../src/assets/images/leadership4.svg";
import HybridImg from "../../assets/images/HybirdImg.svg";
import FreelanceImg from "../../assets/images/FreelanceImg.svg";
import CTRimg from "../../assets/images/CTRRICON.png";
import Cpcimg from "../../assets/images/CPCCicon.png";
import CPRimg from "../../assets/images/CPRRIcon.png";

export const USERS_BLOGS = [
  {
    img: CountImg,
    name: "KAYODE OSINUSI",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
  {
    img: devices,
    name: "MIKLOS PHILIPS",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
  {
    img: devices,
    name: "KAYODE OSINUSI",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
  {
    img: CountImg,
    name: "MIKLOS PHILIPS",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
  {
    img: devices,
    name: "ANAK GARCIA",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
  {
    img: personScreen,
    name: "KAYODE OSINUSI",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ..",
  },
];
export const ABOUT_USERS = [
  { img: leadership1, name: "Taso Du Val", designation: "CEO" },
  { img: leadership2, name: "Brenda Kurz", designation: "CAO" },
  { img: leadership3, name: "Bill Tesingo", designation: "General Counsel" },
  { img: leadership4, name: "Simonal tofel", designation: "VP Communication" },
];

export const OBJECTIVES_VALUES = [
  {
    img: Cpcimg,
    heading: "Cost Per Click",
    about:
      "Cost Per Click (CPC) refers to the actual price you pay for each click in your pay-per-click (PPC) marketing campaigns.",
  },
  {
    img: CTRimg,
    heading: "Click-Through-Rate",
    about:
      "Refer to as Click-Through-Rate measure the success of an online advertising campaign for a particular website .",
  },
  {
    img: CPRimg,
    heading: "Cost Per Lead",
    about:
      "Cost per lead, is an online advertising pricing model, where the advertiser pays for an explicit sign-up from a consumer.",
  },
];

export const CALCULATE_BDGT = [
  {
    heading: "SEM (Search)",
    text: "In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia nunc. Aliquam erat volutpat.",
  },
  {
    heading: "Social Media",
    text: "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor.",
  },
  {
    heading: "Display",
    text: "Earth is the third planet from the Sun and the only astronomical object known to harbor life.",
  },
  {
    heading: "Affiliates",
    text: "Earth is the third planet from the Sun and the only astronomical object known to harbor life.",
  },
];

export const MORE_ARTICLE = [
  {
    img: HybridImg,
    name: "KAYODE OSINUSI",
    heading: "Make It Count – A Guide to Measuring the User Experience",
    about:
      "As designers, we want the products we build to be satisfying and easy to use, but how can we know that’s the case? We start by measuring the user experience..",
  },
  {
    img: FreelanceImg,
    name: "KAYODE OSINUSI",
    heading:
      "Living His Best Life – David Nuff's Inspiring Freelance Success Story",
    about:
      "David Nuff is part of the Toptal network, where he works as a freelance design consultant. The ability to work remotely has fueled his life plan for ....",
  },
];

export const CE_AUTH = "CE_AUTH";
export const TOKEN_KEY = "bs-auth-token";
export const TEST = "";

export const EXPERIENCE_DATA = [
  { id: "1", label: "1-10 Years" },
  { id: "2", label: "11-20 Years" },
  { id: "3", label: "21-30 Years" },
  { id: "4", label: "31-40 Years" },
  { id: "5", label: "40+ Years" },
];
export const OBJECTIVES = [
  { id: "1", label: "Conversion" },
  { id: "2", label: "Clicks" },
  { id: "3", label: "Impressions" },
];
export const CAPITALIZE = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};
export const TO_CAPITALIZED_WORDS = (word: string) => {
  var words = word?.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(CAPITALIZE).join(" ");
};

export const FIXED = (x: any) => {
  const value: string = Number.parseFloat(x).toFixed(2);
  return value === "NaN" ? "-" : value;
};
