import React from "react";
import MainLogo1 from "../../../assets/images/MainLogo1.svg";

const MainFooter = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <img src={MainLogo1} alt="element-2" className=" align-top mb-3" />

            <div className="footer-text fs-5 mb-3"></div>
          </div>
          <div className="col-lg-5 col-md-12">
            <h4>Signup to our NewsLetter</h4>
            <div className="w-100">
              <input type="email" placeholder="Enter Email" />
              <button className="signupBtn">Sign Up</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
