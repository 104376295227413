import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import MainLogo from "../../../assets/images/MainLogo1.svg";
import { PASSWORD_REGEX, REQUIRED_FIELD, validationSchemaResetPassword } from "../../../components/config/validation";
import * as authActions from ".././../../redux/auth/authActions";
import { InputText } from "../../../components/forms/inputText";
import { PasswordChangeModal } from "../../../components/dialogs/PasswordChangeSucces";
let initValue = {
  password: "",
};
const ResetPassword = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch: any = useDispatch();
  const { token } = useParams();
  const {
    // watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initValue,
    resolver: yupResolver(validationSchemaResetPassword),
  });

  const { passwordError } = useSelector(
    (state: any) => ({
      passwordError: state?.authentication?.passwordError,
    }),
    shallowEqual
  );

  const onSubmit = async (data: any) => {
    if (token) {
      dispatch(authActions.resetPassword(data, token, setModalShow));
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container ">
          <div className="row">
            <div className="text-center">
              <Link to={"/"}>
                <img src={MainLogo} alt="" className="logoImg" />
              </Link>
            </div>
            <div className="col-lg-12 main-card  ">
              <div className="signUp-form ">
                <div className="card border-0">
                  <div className="card-body">
                    <h3>Reset your password</h3>

                    <div className="email mb-3">
                      <p>Password</p>
                      <InputText
                        className={"w-100"}
                        type="password"
                        name="password"
                        register={register}
                        validations={{
                          ...{
                            required: {
                              value: true,
                              message: `${REQUIRED_FIELD}`,
                            },
                            pattern: {
                              value: PASSWORD_REGEX,
                              message: `${REQUIRED_FIELD}`,
                            },
                          },
                        }}
                        error={errors["password"]}
                        autoFocus
                      />
                      <div className="error">{passwordError}</div>
                    </div>

                    <div className="text-center ">
                      <button type="submit" className="loginBtn border-0 mb-2 mt-3 ">
                        Confirm
                      </button>

                      <Link to={"/LogIn"}>
                        <button className="returnBtn border-0 mt-4 mb-4">Return To Sign In</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PasswordChangeModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ResetPassword;
