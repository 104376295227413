import axios from "axios";

export function getIndustries() {
  return axios.get(`/industries?limit=500&page=1`);
}

export function userEstimation(estimation: any) {
  return axios.post("/userEstimationLog", estimation);
}

export function getEstimations(id: any) {
  return axios.get(`/userEstimationLog/${id}`);
}
export function getVerifyPayment() {
  return axios.get(`/payments/verify`);
}

export function postPayment(hostUrl: any) {
  return axios.post(`/payments`, hostUrl);
}

export function campaignEstimation() {
  return axios.get(`/userEstimationLog?limit=10&page=1`);
}

export function campaignEstimationById(id: number) {
  return axios.get(`/userEstimationLog/calculation/${id}`);
}

export function updateUserEstimation(estimationCreate: any, id: number) {
  return axios.patch(`/userEstimationLog/${id}`, estimationCreate);
}
