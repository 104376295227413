import React, { useEffect, useState } from "react";
import {
  useDispatch,
  // shallowEqual,
  // useSelector
} from "react-redux";
// import { useNavigate } from "react-router-dom";
// import * as authActions from "../../redux/auth/authActions";
import * as estimateActions from "../../redux/Estimation/estimationActions";
import { Alert, Snackbar } from "@mui/material";
import CongratulationsCard  from './congrats'

export function SuccessModal({ show, onHide }: any) {
  const dispatch: any = useDispatch();
  const [open, setOpen] = useState(true);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  // const navigate = useNavigate();
  useEffect(() => {
    dispatch(estimateActions.getVerifyPayment());
    // eslint-disable-next-line
  }, []);

  // const handleClick = () => {
  //   navigate("/");
  //   dispatch(authActions.whoAmI());

  //   // navigate(`/calculator/${payment?.estimationId}`);
  // };
  return (
    <>
      <CongratulationsCard />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Payment Successful
        </Alert>
      </Snackbar>
    </>
  );
}
