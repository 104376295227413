import React, { useEffect, useState } from "react";
import MainLogo from "../../../../../assets/images/LogoSider.png";
import { MdSpaceDashboard } from "react-icons/md";
import * as authActions from "../../../../../redux/auth/authActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TO_CAPITALIZED_WORDS } from "../../../../../components/config/constant";

const Layout = ({ children }: any) => {
  const [resSidebar, setResSidebar] = useState(false);
  // const [toggleSidebar, setToggleSidebar] = useState(false);
  const dispatch: any = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setResSidebar(true);
      } else {
        setResSidebar(false);
      }
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const { user } = useSelector(
    (state: any) => ({
      user: state?.authentication?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(authActions.whoAmI());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="layout">
      <div className="wrapper d-flex align-items-stretch">
        {!resSidebar && (
          <nav id="sidebar">
            <div className="p-4">
              <Link to={"/"}>
                <img src={MainLogo} alt="" />
              </Link>
              <ul className="list-unstyled components mt-5 mb-5">
                <Link to="/">
                  <li>
                    <div className="d-flex ">
                      <MdSpaceDashboard className="icon" />
                      <p className="mt-1">Home</p>
                    </div>
                  </li>
                </Link>
                <li>
                  <div className="d-flex ">
                    <MdSpaceDashboard className="icon" />
                    <p className="mt-1">Dashboard</p>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        )}

        <div id="content" className="p-4 p-md-1">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="container-fluid">
              {resSidebar === false ? (
                <button onClick={() => setResSidebar(true)}>
                  <span className="navbar-toggler-icon"></span>
                </button>
              ) : (
                <button onClick={() => setResSidebar(false)}>
                  <span className="navbar-toggler-icon"></span>
                </button>
              )}

              <h5 className="mt-2">{TO_CAPITALIZED_WORDS(user?.name)}</h5>
            </div>
          </nav>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
