import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import HomePage from "./pages/public/homePage/homePage";
import SignupForm from "./pages/public/Forms/signupForm";
import LoginForm from "./pages/public/Forms/loginForm";
import ResetEmail from "./pages/private/Forms/resetEmail";
import ResetPassword from "./pages/private/Forms/resetPassword";
import Payment from "./pages/private/Forms/cards/paymentCard";
import Calculator from "./pages/public/budgetCalculate/calculator";
import Dashboard from "./pages/private/Forms/cards/Campaigns/dashboard";
import { SuccessModal } from "./components/dialogs/successModal";
import { ErrorModal } from "./components/dialogs/errorModal";
import CampaignCalculations from "./pages/private/Forms/cards/Campaigns/campaignCalculations";
import * as authActions from "./redux/auth/authActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ChangePassword from "./pages/private/Forms/cards/changePassword";
import AboutPage from "./pages/private/Forms/aboutPage";
import ContactUsPage from "./pages/private/Forms/contactUsPage";
import PrivacyPage from "./pages/private/Forms/privacyPage";
import TermsAndConditions from "./pages/private/Forms/termsAndConditions";
import CookieConsentBanner from "./components/dialogs/cookie-consent";

function BasePage({ basename }: any) {
  const [location, setLocation] = useState<string>(window.location.pathname);
  const [show, setShow] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const { user } = useSelector(
    (state: any) => ({
      user: state?.authentication?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(authActions.whoAmI());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/payment/success") {
      setShow(true);
    } else if (window.location.pathname === "/payment/failure") {
      setShow(true);
    }
  }, [location]);
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route
          path="/"
          element={<HomePage location={location} setLocation={setLocation} />}
        />
        <Route
          path="/payment"
          element={<Payment location={location} setLocation={setLocation} />}
        />
        <Route
          path="/calculator/:id"
          element={<Calculator location={location} setLocation={setLocation} />}
        />
        <Route
          path="/SignUp"
          element={<SignupForm location={location} setLocation={setLocation} />}
        />
        <Route
          path="/LogIn"
          element={<LoginForm location={location} setLocation={setLocation} />}
        />
        <Route
          path="/verification"
          element={<ResetEmail location={location} setLocation={setLocation} />}
        />
        <Route path="/reset-password:token" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route
          path="/payment/success"
          element={
            <SuccessModal
              show={show}
              onHide={() => {
                setShow(false);
              }}
            />
          }
        />

        <Route
          path="/payment/failure"
          element={
            <ErrorModal
              show={show}
              onHide={() => {
                setShow(false);
              }}
            />
          }
        />

        {user?.paidUser === true ? (
          <Route
            path="/dashboard"
            element={
              <Dashboard location={location} setLocation={setLocation} />
            }
          />
        ) : null}

        <Route
          path="/campaign-calculations/:id"
          element={
            <CampaignCalculations
              location={location}
              setLocation={setLocation}
            />
          }
        />
      </Routes>
      <CookieConsentBanner />
    </BrowserRouter>
  );
}

export default BasePage;
