import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const BudgetSlider = ({
  setState,
  state,
  step,
  min,
  max,
  onChange,
  onFinalChange = (val: number) => {},
}: number | any) => {
  return (
    <>
      <Slider min={min} max={max} step={step} value={state} onChange={onChange} onAfterChange={onFinalChange} />
      <div className="mt-3">
        <label>0%</label>
        <label className="label-right mb-5">100%</label>
      </div>
    </>
  );
};

export default BudgetSlider;
