import React, { useState } from 'react';
import { Snackbar, SnackbarContent, Button, styled } from '@mui/material';

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  background: 'transparent',
  bottom: '20px',
  right: '20px',
  [theme.breakpoints.down('sm')]: {
    bottom: '0',
    left: '0',
    right: '0',
    borderRadius: 0,
  },
}));

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const BannerContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#333',
  padding: theme.spacing(4),
  minHeight: '200px',
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '100%',
    borderRadius: 0,
  },
}));

const ConsentText = styled('span')({
  fontSize: '16px',
  marginBottom: '16px',
  textAlign: 'justify',
});

const PrivacyPolicyLink = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  color: '#777',
  textDecoration: 'underline',
  padding: 0,
  minWidth: 'unset',
}));

const ConsentButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  width: '100%',
  marginTop: '12px',
}));

function CookieConsentBanner() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledSnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <StyledSnackbarContent
        message={
          <BannerContainer>
            <ConsentText>
              {'We use cookies to improve your experience, to remember your preferences, and to show you personalized content based on your interests as detailed in our '}
              <PrivacyPolicyLink
                href="/cookie-policy"
                variant="text"
                size="small"
              >
                Cookie Policy
              </PrivacyPolicyLink>
              {'. By continuing to use capaignestimator.com, you accept our use of cookies.'}
            </ConsentText>
            <ConsentButton
              variant="contained"
              size="small"
              onClick={handleClose}
            >
              Got it!
            </ConsentButton>
          </BannerContainer>
        }
      />
    </StyledSnackbar>
  );
}

export default CookieConsentBanner;
