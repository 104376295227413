import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import MainLogo from "../../../assets/images/MainLogo1.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { EMAIL_REGEX, REQUIRED_FIELD, validationSchemaForgotPassword } from "../../../components/config/validation";
import * as authActions from ".././../../redux/auth/authActions";
import { InputText } from "../../../components/forms/inputText";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EmailSentModal } from "../../../components/dialogs/EmailSentSuccessModal";

let initValue = {
  email: "",
};
const ResetEmail = ({ location, setLocation }: any) => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch: any = useDispatch();
  const {
    // watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initValue,
    resolver: yupResolver(validationSchemaForgotPassword),
  });

  const { emailError } = useSelector(
    (state: any) => ({
      emailError: state?.authentication?.emailError,
    }),
    shallowEqual
  );

  const onSubmit = async (data: any) => {
    dispatch(authActions.passwordForgot(data, setModalShow));
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container ">
          <div className="row">
            <div className="text-center">
              <Link to={"/"}>
                <img src={MainLogo} alt="" className="logoImg" />
              </Link>
            </div>
            <div className="col-lg-12 main-card">
              <div className="signUp-form">
                <div className="card border-0">
                  <div className="card-body">
                    <h3>Reset your password</h3>
                    <p>
                      Enter the email address associated with your account and we'll send you a link to reset your
                      password.
                    </p>
                    <div className="mt-4">
                      <p>Email</p>
                      <InputText
                        className={"w-100"}
                        type="text"
                        name="email"
                        register={register}
                        validations={{
                          ...{
                            required: {
                              value: true,
                              message: `${REQUIRED_FIELD}`,
                            },
                            pattern: {
                              value: EMAIL_REGEX,
                              message: `${REQUIRED_FIELD}`,
                            },
                          },
                        }}
                        error={errors["email"]}
                        autoFocus
                      />
                    </div>
                    <div className="error">{emailError}</div>

                    <div className="text-center ">
                      <button type="submit" className="loginBtn border-0 mb-2 mt-3 ">
                        Continue
                      </button>

                      <Link to={"/LogIn"}>
                        <button className="returnBtn border-0 mt-4 mb-4">Return To Sign In</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <EmailSentModal show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ResetEmail;
