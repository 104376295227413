import React from "react";
import MainLogo1 from "../../../assets/images/MainLogo1.svg";
const HomePageFooter = () => {
  return (
    <footer>
  <div className="container">
    <div className="row text-center">
      <div className="col-lg-5 col-md-12 col-12">
        <img src={MainLogo1} alt="element-2" className="align-top mb-3" />
        <div className="footer-text fs-5 mb-3"></div>
      </div>

      <div className="col-lg-3 col-6 col-md-6">
        <h5>Products</h5>
        <div className="footer-divider"></div>
        <p>For Brands</p>
        <p>For Agencies</p>
        <p>Influencer Market</p>
      </div>

      <div className="col-lg-3 col-6 col-md-6">
        <h5>Company</h5>
        <div className="footer-divider"></div>
        <p>About</p>
        <p>Career</p>
        <p>Partner</p>
      </div>
    </div>
  </div>
</footer>


  );
};

export default HomePageFooter;
