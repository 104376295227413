import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MainSlider from "../estimateSlider";
import * as estimateActions from "../../../../redux/Estimation/estimationActions";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { validationFields } from "../../../../components/config/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactSelect from "./reactSelect";
import { EXPERIENCE_DATA } from "../../../../components/config/constant";
import { OBJECTIVES } from "./../../../../components/config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type CountryType = {
  value: string;
  label: string;
};

const options: CountryType[] = [
  { value: "PK", label: "Pakistan" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "US", label: "United States" },
  { value: "GB", label: "United Kingdom" },
  { value: "IN", label: "India" },
  { value: "", label: "Rest of the world" },
];

const BudgetCard = () => {
  let navigate = useNavigate();

  const dispatch: any = useDispatch();

  const [firstPageData, setFirstPageData] = useState({
    country: { name: "", label: "" },
    industry: { name: "", label: "" },
    objective: { name: "", label: "" },
    userExperience: { name: "", label: "" },
    totalBudget: 0,
  });

  const [data, setData] = useState([] as any);

  const { user, industryData, error, actionsLoading } = useSelector(
    (state: any) => ({
      // estimationError: state?.estimator?.estimationError,
      industryData: state?.estimator?.industries,
      user: state?.authentication?.user,
      error: state?.estimator?.estimationError,
      actionsLoading: state?.estimator?.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(estimateActions.getIndustry());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (options && options.length) {
      setData([...options]);
    }
    // eslint-disable-next-line
  }, [options]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationFields),
  });

  const onSubmit = (data: any) => {
    if (user === undefined) {
      navigate("/SignUp");
      dispatch(
        estimateActions.storeInitData({
          campaignData: {
            country: data.country.label,
            industry: data.industry.value,
            objective: data.objective.label,
            userExperience: data.userExperience.label,
            totalBudget: firstPageData.totalBudget,
          },
        })
      );
    } else {
      dispatch(
        estimateActions.postEstimation(
          {
            campaignData: {
              country: data.country.label,
              industry: data.industry.value,
              objective: data.objective.label,
              userExperience: data.userExperience.label,
              totalBudget: firstPageData.totalBudget,
            },
          },
          navigate
        )
      );
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="budget-card">
          <div className="container">
            {/* <div className="text-center error">{estikmationError}</div> */}
            <div className="card">
              <div className="card-body">
                <div className="">
                  <div className="select mb-1 ">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <ReactSelect
                          error={errors["industry"]}
                          name="industry"
                          control={control}
                          options={
                            industryData && industryData.length
                              ? industryData.map((item: any) => {
                                  return {
                                    label: item.title,
                                    value: item.id,
                                  };
                                })
                              : []
                          }
                          register={register}
                          label="Industry"
                          prefix={undefined}
                          suffix={undefined}
                          validations={{}}
                          isDisabled={undefined}
                          isReadOnly={undefined}
                          isMulti={undefined}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="IndustryAges">
                            <div className="select mb-3">
                              <ReactSelect
                                error={errors["userExperience"]}
                                name="userExperience"
                                control={control}
                                options={
                                  EXPERIENCE_DATA && EXPERIENCE_DATA.length
                                    ? EXPERIENCE_DATA.map((item: any) => {
                                        return {
                                          label: item.label ? item.label : "",
                                          value: item.id ? item.id : "",
                                        };
                                      })
                                    : []
                                }
                                register={register}
                                label="User Experience"
                                prefix={undefined}
                                suffix={undefined}
                                validations={{}}
                                isDisabled={undefined}
                                isReadOnly={undefined}
                                isMulti={undefined}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 ">
                    <div className="select mb-3">
                      <div className="select mb-3">
                        <ReactSelect
                          error={errors["objective"]}
                          name="objective"
                          control={control}
                          options={
                            OBJECTIVES && OBJECTIVES.length
                              ? OBJECTIVES.map((item: any) => {
                                  return {
                                    label: item.label ? item.label : "",
                                    value: item.id ? item.id : "",
                                  };
                                })
                              : []
                          }
                          register={register}
                          label="Objective"
                          prefix={undefined}
                          suffix={undefined}
                          validations={{}}
                          isDisabled={undefined}
                          isReadOnly={undefined}
                          isMulti={undefined}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="Countries">
                      <div className="select mb-3">
                        <ReactSelect
                          error={errors["country"]}
                          name="country"
                          control={control}
                          options={
                            data && data.length
                              ? data.map((item: any) => {
                                  return {
                                    label: item.label,
                                    value: item.value,
                                  };
                                })
                              : []
                          }
                          register={register}
                          label="Country"
                          prefix={undefined}
                          suffix={undefined}
                          validations={{}}
                          isDisabled={undefined}
                          isReadOnly={undefined}
                          isMulti={undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <MainSlider
                  firstPageData={firstPageData}
                  setFirstPageData={setFirstPageData}
                />

                <div className="text-center">
                  <button
                    disabled={actionsLoading}
                    className={`estimate-btn mt-4 ${
                      actionsLoading ? "disabled-btn" : ""
                    }`}
                    type="submit"
                  >
                    Estimate Campaign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default BudgetCard;
