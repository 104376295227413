import * as yup from "yup";

// yup validations
export const validationSchemaSignIn = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email must be a valid email address"),
  password: yup
    .string()
    .min(8)
    .matches(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required"),
});
export const validationSchemaLogin = yup.object().shape({
  email: yup.string().required("Email is required").email("Email must be a valid email address"),
  password: yup.string().required("Password is required"),
});
export const validationSchemaContactUs = yup.object().shape({
  name: yup.string().required("Name is required"),
  message: yup.string().required("Please write a message"),
  email: yup.string().required("Email is required").email("Email must be a valid email address"),
});
export const validationSchemaForgotPassword = yup.object().shape({
  email: yup.string().required("Email is required").email("Email must be a valid email address"),
});
export const validationSchemaResetPassword = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .matches(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required"),
});
export const validationSchemaChangePassword = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required").min(8)
  .matches(
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  newPassword: yup.string().required("New Password is required").min(8)
  .matches(
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
});

export const validationFields = yup.object().shape({
  industry: yup.object().shape({
    label: yup.string().required("Industry is Required ").nullable(),
    value: yup.string().required("Industry is Required ").nullable(),
  }),
  country: yup.object().shape({
    label: yup.string().required("country is Required ").nullable(),
    value: yup.string().required("country is Required ").nullable(),
  }),
  objective: yup.object().shape({
    label: yup.string().required("objective is Required").nullable(),
    value: yup.string().required("objective is Required").nullable(),
  }),
  userExperience: yup.object().shape({
    label: yup.string().required("userExperience is Required").nullable(),
    value: yup.string().required("userExperience is Required").nullable(),
  }),
});

//validations
// eslint-disable-next-line
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// eslint-disable-next-line
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z]).{8,20}$/;

export const NUMBER_REGEX = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
// export const URL_REGEX = /(https?:\/\/[^\s]+)/g;
//Messages
export const AN_ERROR_OCCURED = "An error occured please try again later.";
export const INVALID_EMAIL = "Please enter a valid email address.";
export const INVALID_PASSWORD = "Please enter a valid password";
export const CONFIRM_PASSWORD = "Passwords does not match";
export const INVALID_NUMBER_REGEX = "Amount must be in numbers";
export const INVALID_PASSWORD_REGEX = "Password must be at least eight characters long, Contain letters and numbers";
export const INVALID_PHONE = "Please enter a valid phone number";
export const INVALID_NAME = "Please enter your name";
export const REQUIRED_FIELD = "is required.";
export const EMAIL_SENT = "Email sent successfully.";
export const EMAIL_VERIFIED = "Email verified.";
export const INVALID_CODE = "Invalid code or the code has expired.";
export const INVALID_LINK = "Invalid link or the link has expired.";
export const MIN_LENGTH = (length: number) => {
  return `Minimum ${length} symbols`;
};
export const MAX_LENGTH = (length: number) => {
  return `Maximum ${length} symbols`;
};
