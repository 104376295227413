import { Fragment, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FIXED } from "../../../components/config/constant";
import * as estimateActions from "../../../redux/Estimation/estimationActions";
import { useNavigate } from "react-router-dom";

const CalculatorResult = ({
  user,
  totalSem,
  totalSocialMedia,
  totalDisplay,
  totalAffiliates,
}: any) => {
  const { id } = useParams();
  const dispatch: any = useDispatch();

  let Url = {
    callback_url: `${window.location.host}`,
    estimationId: id,
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleEstimate = () => {
    setIsLoading(true);
    dispatch(estimateActions.paymentPost(Url));
  };
  const { calculations } = useSelector(
    (state: any) => ({
      calculations: state?.estimator?.estimationForEdit,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Fragment>
      <div className="calculator">
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <h4>Results</h4>
              <hr />
              <p>Total Budget</p>
              <span>{calculations?.campaignData?.totalBudget}</span>
              <hr />
              <p>Country</p>
              <span>{calculations?.campaignData?.country}</span>
              <hr />
              <p>objective</p>
              <span>{calculations?.campaignData?.objective}</span>
              <hr />
              <p>Industry</p>
              <span>{calculations?.campaignDetails?.industry?.title}</span>
              <hr />
              {calculations?.campaignData?.objective === "Clicks" ? (
                <>
                  <p>{calculations?.campaignData?.objective}</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.noOfClicks)}
                  </span>
                  <hr />
                  <p>Impressions</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.impression)}
                  </span>
                  <hr />
                </>
              ) : calculations?.campaignData?.objective === "Conversion" ? (
                <>
                  <p>{calculations?.campaignData?.objective}</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.conversion)}
                  </span>
                  <hr />
                  <p>Impressions</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.impression)}
                  </span>
                  <hr />
                  <p>Number OF Clicks</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.noOfClicks)}
                  </span>
                  <hr />
                  <p>CPA</p>
                  <span>{FIXED(calculations?.estimationResults?.cpa)}</span>
                  <hr />
                </>
              ) : (
                <>
                  <p>Impressions</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.impression)}
                  </span>
                  <hr />
                  <p>Number OF Clicks</p>
                  <span>
                    {FIXED(calculations?.estimationResults?.noOfClicks)}
                  </span>
                  <hr />
                </>
                // : calculations?.campaignData?.objective === "Lead" ? (
                //   <>
                //     <p>{calculations?.campaignData?.objective}</p>
                //     <span>
                //       {FIXED(calculations?.estimationResults?.noOfLeads)}
                //     </span>
                //     <hr />
                //     <p>Impressions</p>
                //     <span>
                //       {FIXED(calculations?.estimationResults?.impression)}
                //     </span>
                //     <hr />
                //     <p>CPL</p>
                //     <span>{FIXED(calculations?.estimationResults?.cpl)}</span>
                //   </>
                // )
              )}

              {user?.paidUser === false ? (
                <>
                  <p>Results</p>
                  <button
                    type="button"
                    className="btn btn-primary more-result"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    All Campaigns Breakdown (Premium)
                  </button>
                </>
              ) : (
                <button
                  onClick={navigateToDashboard}
                  type="button"
                  className="btn btn-primary more-result"
                >
                  Go To Dashboard
                </button>
              )}
              {(totalSem > 1 ||
                totalDisplay > 1 ||
                totalSocialMedia > 1 ||
                totalAffiliates > 1) && (
                <div>
                  {" "}
                  {user?.paidUser && (
                    <div className="mt-2">
                      <button
                        onClick={navigateToDashboard}
                        type="button"
                        className="btn btn-primary more-result"
                      >
                        Update Results
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered  modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="card">
                        <div className="head">
                          <div className="row">
                            <div className="col-6">
                              <h5 className="text-start">Your Order Summary</h5>
                            </div>
                            <div className="col-6">
                              <h5 className="text-end">Price in USD</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-7 ">
                              <p className="text-start">
                                keyword Tool Pro Plus (12-months subscription)
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="text-end">$9.99/months</p>
                            </div>
                          </div>
                          <hr className="w-100" />
                          <div className="row">
                            <div className="col-lg-7 "></div>
                            <div className="col-lg-3 col-6 text-start fw-bold">
                              <h5>Payment Due</h5>
                            </div>
                            <div className="col-lg-2 col-6 text-end fw-bold">
                              <h5>$9.99</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modalFooter">
                      <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5 ">
                          <h6>Subscribe to unlock more results</h6>
                        </div>
                        <div className="col-lg-3 ">
                          <div className="subscribe-btn w-100">
                            {!isLoading && (
                              <button
                                onClick={handleEstimate}
                                className="subscribe"
                              >
                                Subscribe
                              </button>
                            )}
                            {isLoading && (
                              <button
                                onClick={handleEstimate}
                                className="subscribe"
                              >
                                Subscribe
                                <div className="spinner-border text-light "></div>
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 footer">
                <div>
                  <h2 className="pt-3">CE Calculations</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CalculatorResult;
