import axios from "axios";

export const AUTH_URL = process.env.REACT_APP_SERVER_URL + "auth";
export const RESET_PASSWORD_URL = process.env.REACT_APP_SERVER_URL + "/auth/reset-password";

export function login(data: any) {
  return axios.post(`${AUTH_URL}/login`, data);
}

export function sign(data: any) {
  return axios.post(`${AUTH_URL}/sign`, data);
}

export function register(item: { name: any; email: any; password: any }) {
  let registerData = {
    name: item?.name,
    email: item?.email,
    password: item?.password,
  };
  return axios.post(`${AUTH_URL}/register`, registerData);
}

export function whoAmI() {
  return axios.get(`${AUTH_URL}/who-am-i`);
}

export function forgotPassword(data: any) {
  return axios.post(`${AUTH_URL}/forgot-password`, data);
}
export function resetPassword(data: any, token: any) {
  return axios.post(`${RESET_PASSWORD_URL}?token${token}`, data);
}
export function changePassword(data: any) {
  return axios.patch(`${AUTH_URL}/change-password`, data);
}
