import React, { Fragment } from "react";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import PhoneIcon from "../../../assets/images/phoneIcon.svg";
import IconMail1 from "../../../assets/images/iconMail1.svg";
import { InputText } from "../../../components/forms/inputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import {
  EMAIL_REGEX,
  REQUIRED_FIELD,
  validationSchemaContactUs,
} from "../../../components/config/validation";
import * as connectActions from "../../../redux/contact/contactActions";
import { useDispatch } from "react-redux";

let initValues: {
  name: "";
  email: "";
  message: "";
};

const ContactCard = () => {
  const dispatch: any = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchemaContactUs),
  });

  const onSubmit = async (data: any) => {
    await dispatch(connectActions.postMessage(data));
    reset();
    toast.success("Your message has been sent.");
  };

  return (
    <Fragment>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="contactCard mt-5">
          <div className="card ">
            <div className="row">
              <div className="col-md-7">
                <h3 className="fs-1 text-center pt-5">Contact Us</h3>
                <div className=" contactInnerCard  d-none d-md-block d-light">
                  <div className="card-body">
                    <div className="d-flex">
                      <img src={PhoneIcon} alt="" className="contact-icons" />
                      <p className="mt-3">281-277-3949</p>
                    </div>

                    <div className="d-flex">
                      <img src={IconMail1} alt="" className="contact-icons" />
                      <p className="mt-3">campaignestimator@glob.net</p>
                    </div>

                    <div className="Blur-effect-img"></div>

                    <div className="brand-icons fs-4">
                      <FaFacebookF className="brandNestIcons mr-2" />
                      <FaTwitter className="brandNestIcons mr-2" />
                      <ImLinkedin2 />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="inner-ContactCard ">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <p>Your Name</p>
                          <InputText
                            className={"w-100"}
                            placeholder="Name"
                            type="text"
                            name="name"
                            register={register}
                            validations={{
                              ...{
                                required: {
                                  value: true,
                                  message: `${REQUIRED_FIELD}`,
                                },
                                pattern: {
                                  value: EMAIL_REGEX,
                                  message: `${REQUIRED_FIELD}`,
                                },
                              },
                            }}
                            error={errors["name"]}
                            autoFocus
                          />
                          <p>Mail</p>
                          <InputText
                            className={"w-100"}
                            placeholder="Email Address"
                            type="text"
                            name="email"
                            register={register}
                            validations={{
                              ...{
                                required: {
                                  value: true,
                                  message: `${REQUIRED_FIELD}`,
                                },
                                pattern: {
                                  value: EMAIL_REGEX,
                                  message: `${REQUIRED_FIELD}`,
                                },
                              },
                            }}
                            error={errors["email"]}
                            autoFocus
                          />

                          <p>Message</p>
                          <InputText
                            className={"w-100"}
                            placeholder="Message"
                            type="text"
                            name="message"
                            register={register}
                            validations={{
                              ...{
                                required: {
                                  value: true,
                                  message: `${REQUIRED_FIELD}`,
                                },
                                pattern: {
                                  value: EMAIL_REGEX,
                                  message: `${REQUIRED_FIELD}`,
                                },
                              },
                            }}
                            error={errors["message"]}
                            autoFocus
                            textarea
                          />

                          <div className="mt-4 text-center ">
                            <button
                              // onClick={onSubmit}
                              type="submit"
                              className="SndMsgBtn"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ContactCard;
