import React, { Fragment, useEffect } from "react";
import MainLogo1 from "../../assets/images/MainLogo1.svg";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer/footer";
import * as authActions from "../../redux/auth/authActions";
import HomePageFooter from "./footer/homePageFooter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TO_CAPITALIZED_WORDS } from "../config/constant";
import * as authAction from "../../redux/auth/authActions";
import * as estimationActions from "../../redux/Estimation/estimationActions";
import { Box } from "@mui/material";

const Layout = ({ children }: any) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(
    (state: any) => ({
      user: state?.authentication?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(authActions.whoAmI());
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <header>
        <>
 
          <Navbar collapseOnSelect expand="lg">
            <Container>
              <Navbar.Brand className="d-flex mt-3">
                <Link to={"/"}>
                  <img src={MainLogo1} alt="element-2" className=" align-top" />
                </Link>
              </Navbar.Brand>
              {!user && (
                <Box
                  className="login"
                  sx={{
                    display: {
                      xs: "block",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  <Nav className="mt-3">
                    <Link className="headerLink" to={"/LogIn"}>
                      <Button className="signupBtn login">Login</Button>
                    </Link>
                  </Nav>
                </Box>
              )}

              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mt-3"/>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mx-auto">
                  <Nav.Link>
                    <Link className="headerLink" to={"/about"}>
                      About Us
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link className="headerLink" to={"/contact"}>
                      Contact Us
                    </Link>
                  </Nav.Link>
                  {user?.paidUser === true ? (
                    <Nav.Link>
                      <Link className="headerLink" to={"/dashboard"}>
                        Dashboard
                      </Link>
                    </Nav.Link>
                  ) : null}
                </Nav>
                <span className="mt-3 text-end">
                  <b className="name">{TO_CAPITALIZED_WORDS(user?.name)}</b>
                </span>
                {user ? (
                  <>
                    <div className="login">
                      <Nav className="mt-3">
                        <Nav.Link>
                          <Link
                            className="headerLink mr-3"
                            to={"/change-password"}
                          >
                            Change Password
                          </Link>
                        </Nav.Link>
                        <div className="divider d-none d-lg-block"></div>
                        <Nav.Link
                          onClick={() => {
                            dispatch(
                              estimationActions.resettingEstimationState()
                            );
                            dispatch(authAction.logout());
                            navigate("/");
                          }}
                        >
                          Logout
                        </Nav.Link>
                      </Nav>
                    </div>
                  </>
                ) : (
                  <div className="login">
                    <Nav>
                      <Nav.Link>
                        <Link className="headerLink " to={"/LogIn"}>
                          Login
                        </Link>
                      </Nav.Link>
                      <div className="divider d-none d-lg-block"></div>
                      <Link className="headerLink" to={"/SignUp"}>
                        <Button className="signupBtn">Sign Up</Button>
                      </Link>
                    </Nav>
                  </div>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {children}
          {window.location.pathname === "/" ? (
            <>
              <HomePageFooter />
              <Footer />
            </>
          ) : (
            <Footer />
          )}
        </>
      </header>
    </Fragment>
  );
};

export default Layout;
