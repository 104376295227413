import React from "react";
import Layout from "../../../components/Layout/header";

const PrivacyPage = () => {
  return (
    <Layout>
      <div className="about" style={{ color: "#000" }}>
        <h1 style={{ color: "blue" }}>
          Campaign Estimator Corporation Privacy Policy
        </h1>
        <p>
          <strong>Last Updated:</strong> 8 September 2023
        </p>

        <p>
          Campaign Estimator Corporation ("we," "our," or "us") is committed to
          protecting the privacy and security of your personal information. This
          Privacy Policy outlines our practices regarding the collection, use,
          and disclosure of information when you use our website located at
          [Insert Website URL] (the "Website").
        </p>

        <p>
          By accessing or using our Website, you consent to the terms and
          practices described in this Privacy Policy. If you do not agree with
          the terms of this Privacy Policy, please do not use the Website.
        </p>

        <h2>Information We Collect</h2>
        <h3>1. Personal Information</h3>
        <p>
          We may collect and store the following personal information when you
          interact with our Website:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>User-generated content (e.g., comments, reviews, or feedback)</li>
        </ul>

        <h2>Non-Personal Information</h2>
        <p>
          We also collect non-personal information that does not directly
          identify you, including:
        </p>
        <ul>
          <li>Internet Protocol (IP) addresses</li>
          <li>Browser type and version</li>
          <li>Pages viewed</li>
          <li>Date and time of your visit</li>
          <li>Device information (e.g., device type, operating system)</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We may use the information we collect for the following purposes:</p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To respond to your inquiries or requests.</li>
          <li>
            To send you updates, newsletters, and marketing communications.
          </li>
          <li>To analyze and track Website usage.</li>
          <li>
            To protect against unauthorized access, use, or disclosure of
            information.
          </li>
          <li>To comply with legal obligations.</li>
        </ul>

        <h2>Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to enhance your
          experience on our Website. Cookies are small text files stored on your
          device that help us analyze website usage and customize content. You
          can control cookies through your browser settings.
        </p>

        <h2>Information Sharing</h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share your information with:
        </p>
        <ul>
          <li>
            Service providers: We may share information with third-party service
            providers who assist us in providing and improving our services.
          </li>
          <li>
            Legal obligations: We may disclose your information if required by
            law, court order, or other legal process.
          </li>
          <li>
            Business transfers: In the event of a merger, acquisition, or sale
            of all or part of our assets, your information may be transferred to
            the acquiring entity.
          </li>
        </ul>

        <h2>Security</h2>
        <p>
          We employ reasonable security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is 100% secure. We cannot guarantee absolute
          security.
        </p>

        <h2>Your Choices</h2>
        <p>
          You can update, correct, or delete your personal information by
          contacting us at [Insert Contact Email Address]. You may also
          unsubscribe from our marketing communications by following the
          instructions in the emails we send.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          Our Website is not intended for children under the age of 13. We do
          not knowingly collect or solicit personal information from children.
          If you believe we have collected information from a child, please
          contact us immediately.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy to reflect changes in our practices
          or for other operational, legal, or regulatory reasons. We will notify
          you of any material changes by posting the updated policy on our
          Website.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our data practices, please contact us at:
        </p>
        <address>
          <p>Campaign Estimator Corporation</p>
          <p>8 The Green, Ste R</p>
          <p>Dover, DE 19901</p>
          <p>Email: campaignestimator@gmail.com</p>
        </address>

        <h1>Campaign Estimator Corporation Cookies Policy</h1>
        <p>
          <strong>Last Updated:</strong> 8 September 2023
        </p>

        <p>
          Campaign Estimator Corporation ("we," "our," or "us") uses cookies and
          similar tracking technologies on our website located at [Insert
          Website URL] (the "Website"). By using the Website, you consent to the
          use of cookies as described in this Cookies Policy.
        </p>

        <h2>What Are Cookies?</h2>
        <p>
          Cookies are small text files that are placed on your computer or
          mobile device when you visit a website. They are widely used to make
          websites work efficiently, enhance user experience, and provide
          information to website owners.
        </p>

        <h2>Types of Cookies We Use</h2>
        <h3>1. Necessary Cookies</h3>
        <p>
          These cookies are essential for the Website to function properly. They
          enable core functionalities such as page navigation and access to
          secure areas of the Website. The Website cannot function properly
          without these cookies.
        </p>

        <h3>2. Performance Cookies</h3>
        <p>
          Performance cookies collect information about how visitors use the
          Website, such as which pages are visited most often and if users
          encounter error messages. These cookies do not collect personally
          identifiable information; the data is aggregated and anonymous.
        </p>

        <h3>3. Functional Cookies</h3>
        <p>
          Functional cookies allow the Website to remember choices you make
          (such as your username, language, or region) and provide enhanced,
          personalized features. These cookies may also be used to remember
          changes you have made to text size, fonts, and other parts of web
          pages that you can customize.
        </p>

        <h3>4. Analytical or Advertising Cookies</h3>
        <p>
          We may use analytical and advertising cookies to track user behavior
          on the Website and provide targeted advertising based on user
          interests. These cookies may also collect information about your
          online activities, such as the websites you visit and the links you
          click.
        </p>

        <h2>How We Use Cookies</h2>
        <p>We use cookies for the following purposes:</p>
        <ul>
          <li>To improve the functionality and performance of the Website.</li>
          <li>
            To analyze how users interact with the Website and make
            improvements.
          </li>
          <li>
            To personalize your experience by remembering your preferences.
          </li>
          <li>To provide relevant advertising and marketing.</li>
        </ul>

        <h2>Managing Cookies</h2>
        <p>
          Most web browsers allow you to control and manage cookies through
          their settings. You can configure your browser to block or delete
          cookies, or you can set your browser to notify you when a website
          requests to store a cookie. However, please note that disabling
          cookies may affect the functionality of the Website.
        </p>

        <p>
          For more information on how to manage cookies in your specific
          browser, you can refer to the browser's help documentation.
        </p>

        <h2>Third-Party Cookies</h2>
        <p>
          We may use third-party services (e.g., Google Analytics) that use
          cookies to collect data for analytics or advertising purposes. These
          third-party services have their own privacy policies and may use their
          cookies in accordance with their policies.
        </p>

        <h2>Changes to this Cookies Policy</h2>
        <p>
          We reserve the right to update this Cookies Policy to reflect changes
          in our use of cookies or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes by posting the
          updated policy on the Website.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Cookies Policy or the
          use of cookies on our Website, please contact us at:
        </p>
        <address>
          <p>Campaign Estimator Corporation</p>
          <p>8 The Green, Ste R</p>
          <p>Dover, DE 19901</p>
          <p>Email: campaignestimator@gmail.com</p>
        </address>
      </div>
    </Layout>
  );
};
export default PrivacyPage;
