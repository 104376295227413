import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX, REQUIRED_FIELD, validationSchemaLogin } from "../../../components/config/validation";
import { InputText } from "../../../components/forms/inputText";
import MainLogo from "../../../assets/images/MainLogo1.svg";
import * as authActions from ".././../../redux/auth/authActions";

let initValue = {
  email: "",
  password: "",
};

const LoginForm = ({ location, setLocation }: any) => {
  const dispatch: any = useDispatch();
  let navigate = useNavigate();

  const { error, estimationData } = useSelector(
    (state: any) => ({
      error: state?.authentication?.loginError,
      estimationData: state?.estimator?.initDataStore,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initValue,
    resolver: yupResolver(validationSchemaLogin),
  });

  const onSubmit = async (data: any) => {
    dispatch(authActions.login(data, navigate, estimationData));
  };

  useEffect(() => {
    setLocation(window.location.pathname);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container ">
          <div className="row">
            <div className="text-center">
              <Link to={"/"}>
                <img src={MainLogo} alt="" className="logoImg" />
              </Link>
            </div>

            <div className="col-lg-12 main-card ">
              <div className="signUp-form ">
                <div className="card border-0">
                  <div className="card-body">
                    <h3>Sign in to your account</h3>
                    <div className="error">{error}</div>

                    <p>Email</p>
                    <InputText
                      className={"w-100"}
                      type="text"
                      name="email"
                      register={register}
                      validations={{
                        ...{
                          required: {
                            value: true,
                            message: `${REQUIRED_FIELD}`,
                          },
                          pattern: {
                            value: EMAIL_REGEX,
                            message: `${REQUIRED_FIELD}`,
                          },
                        },
                      }}
                      error={errors["email"]}
                      autoFocus
                    />

                    <div className="mb-3 mt-4">
                      <p>Password</p>
                      <InputText
                        className={"w-100"}
                        type="password"
                        name="password"
                        register={register}
                        validations={{
                          ...{
                            required: {
                              value: true,
                              message: `${REQUIRED_FIELD}`,
                            },
                          },
                        }}
                        error={errors["password"]}
                      />
                    </div>

                    <div className=" text-end mb-3">
                      <Link to={"/verification"} className="textforget">
                        Forgot your password?
                      </Link>
                    </div>

                    <div className="text-center mt-5">
                      <button type="submit" className="submit loginBtn border-0 mb-2">
                        Log In
                      </button>
                    </div>

                    <div className="text-center mt-4">
                      <div className="textforget mb-3">
                        Don’t have account?
                        <Link className="signUp" to={"/SignUp"}>
                          Create account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LoginForm;
