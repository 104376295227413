import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as estimateActions from "../../redux/Estimation/estimationActions";

export function ErrorModal({ show, onHide }: any) {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(estimateActions.getVerifyPayment());
    // eslint-disable-next-line
  }, []);

  const { payment } = useSelector(
    (state: any) => ({
      payment: state?.estimator?.payment,
    }),
    shallowEqual
  );

  const handleClick = () => {
    navigate(`/calculator/${payment?.estimationId}`);
  };

  return (
    <>
      <Modal show={show} onHide={handleClick}>
        <Modal.Header closeButton>
          <Modal.Title>Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <ImCross className="error-icon" />
            <h5> Your Payment has been failed !</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
