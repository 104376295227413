import { createSlice } from "@reduxjs/toolkit";
import { Storage } from "react-jhipster";
import { TOKEN_KEY } from "../../components/config/constant";

const initialLoginState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  loginError: null,
  emailError: null,
  changepasswordError: null,
  passwordError: null,
  signupError: null,
  user: undefined,
  userAuthEntities: undefined,
  role: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialLoginState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      if (action.payload.errorType === "loginError") {
        state.loginError = action.payload?.errorData;
      }
      if (action.payload.errorType === "signupError") {
        state.signupError = action.payload?.errorData;
      }
      if (action.payload.errorType === "emailError") {
        state.emailError = action.payload?.errorData;
      }
      if (action.payload.errorType === "passwordError") {
        state.passwordError = action.payload?.errorData;
      }
      if (action.payload.errorType === "changepasswordError") {
        state.changepasswordError = action.payload?.errorData;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.loginError = null;
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    // login
    loginState: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    logoutState: (state) => {
      if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
        Storage.local.remove(TOKEN_KEY);
        Storage.session.remove(TOKEN_KEY);
        state.user = undefined;
      } else {
        state.user = undefined;
      }
    },
    // signup
    signupState: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },

    whoAmIState: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.user = entities;
    },

    saveDeviceState: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    passwordForgot: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    resetPassword: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    changePassword: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
  },
});
