import React from "react";
import Layout from "../../../components/Layout/header";
import "../../../assets/scss/about.scss";
import { useNavigate } from "react-router-dom";

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div>
        <div>
          <h1 className="about">About Us</h1>
          <div className="about-paragraph">
            <p> 1. The journey started from a simple question,” How much?”</p>
            <p className="mb-0">
              2. Amid covid-19, when every business wanted to go online, our phone would not stop ringing and people would
              ask questions like,
            </p>
            <div className="about-div">
            “How can we know the ROI against the budget we spend on digital”

            </div>
            <p> 3. “How do we know where to spend our budget online to maximize the ROI?”</p>
            <p> 4. This was our Eureka moment, the very moment when Campaign Estimator was born.</p>
            <p>
              5. We knew that there is a big gap. Not every SME or small business can afford an agency or have the
              capability to calculate the digital ROI. At campaign estimator, we are solving this basic problem.
            </p>
            <u>
              <p>Helping businesses come up with a data driven forecasting for their budgets.</p>
            </u>
          <button  className="layout-margin estimate-btn mt-4" onClick={() => navigate('/')}>Estimate My Campaign</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default AboutPage;
