import React, { Fragment, useEffect } from "react";
import Campaigns from "./campaigns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as estimateActions from "../../../../../redux/Estimation/estimationActions";
import Layout from "./Layout";

const Dashboard = ({ location, setLocation }: any) => {
  const dispatch: any = useDispatch();
  const { estimationCampaign } = useSelector(
    (state: any) => ({
      estimationCampaign: state?.estimator?.campaigns,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(estimateActions.getCampaignsByEstimation());
    setLocation(window.location.pathname);
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Layout>
        <div className="dashboard">
          <div className="heading">My Campaigns</div>
          <div className="row px-5">
            {estimationCampaign?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className="col-xl-4 col-lg-6 col-sm-6"
                  id={item.id}
                >
                  <Campaigns
                    name={item.campaignDetails?.campaignName}
                    industry={item.campaignDetails?.industry}
                    id={item?.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default Dashboard;
