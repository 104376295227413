import { estimationSlice } from "./Estimation/estimationSlice";
import { authSlice } from "./auth/authSlice";
import { connectSlice } from "./contact/contactSlice";
import { combineReducers } from "redux";

export const reducer = combineReducers({
  authentication: authSlice.reducer,
  estimator: estimationSlice.reducer,
  connect: connectSlice.reducer,
});
