import * as requestFromServer from "./estimationCrud";
import { estimationSlice, callTypes } from "./estimationSlice";
const { actions } = estimationSlice;

export const getIndustry = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getIndustries()
    .then((response) => {
      dispatch(
        actions.industriesFetched({
          entities: response.data.results,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getEstimations = (id: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEstimations(id)
    .then((response) => {
      dispatch(
        actions.getEstimation({
          entitiy: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const getVerifyPayment = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getVerifyPayment()
    .then((response) => {
      dispatch(
        actions.postPayment({
          entitiy: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const postEstimation =
  (estimationCreate: any, navigate: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .userEstimation(estimationCreate)
      .then((response) => {
        navigate(`/calculator/${response.data.id}`);
        dispatch(actions.getEstimation({ entitiy: response.data }));
      })
      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "createError",
            errorData,
          })
        );
      });
  };

export const storeInitData = (data: any) => (dispatch: any) => {
  dispatch(
    actions.initDataStore({
      entities: data,
    })
  );
};

export const updateEstimation =
  (estimationCreate: any, id: any) => (dispatch: any) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer

      .updateUserEstimation(estimationCreate, id)

      .then((response) => {
        dispatch(actions.getEstimation({ entitiy: response.data }));
      })

      .catch((error) => {
        error.clientMessage = error.message;
        let errorData = error?.response?.data?.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
            errorType: "createError",
            errorData,
          })
        );
      });
  };

export const paymentPost = (hostUrl: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .postPayment(hostUrl)
    .then((response) => {
      window.open(response.data.url, "_self");
      dispatch(actions.estimationsPost({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = error.message;
      let errorData = error?.response?.data?.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "createError",
          errorData,
        })
      );
    });
};

export const getCampaignsByEstimation = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .campaignEstimation()
    .then((response) => {
      dispatch(
        actions.getCampaignEstimation({
          entities: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const campaignCalculation = (id: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .campaignEstimationById(id)
    .then((response) => {
      dispatch(
        actions.getEstimationById({
          entity: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resettingEstimationState = () => (dispatch: any) => {
  dispatch(actions.resetEstimationState());
};
