import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/scss/style.scss";
import { Provider } from "react-redux";
import BasePage from "./basePage";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import setupAxios from "./redux/setupAxios";
import {logout} from "./redux/auth/authActions"
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
 // @ts-ignore

 setupAxios(logout);
//  setupAxios(axios, store);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BasePage basename={'/'} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
