import { createSlice } from "@reduxjs/toolkit";

const initialConnectState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  entities: null,
  contact: null,
  ConnectError: undefined,
  getMediumBlogs: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const connectSlice = createSlice({
  name: "connect",
  initialState: initialConnectState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }

      if (action.payload.errorType === "createError") {
        state.ConnectError = action.payload?.errorData;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.ConnectError = undefined;
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    messagePost: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.contact = entities;
    },
    getMediumBlogs: (state, action) => {
      const { entitiy } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.getMediumBlogs = entitiy;
    },
  },
});
